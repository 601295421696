<template>
  <div
    class="cursor-pointer text-clip rounded-xl border-2 shadow-black/10"
    :class="{ 'border-green-500 shadow-2xl': active, 'border-gray-200': !active }">
    <div class="flex items-center justify-between p-5">
      <h5 class="text-balance text-xl font-semibold">
        {{ title }}
      </h5>

      <Icon
        name="heroicons:chevron-down"
        class="size-8 text-green-500 transition-transform duration-300"
        :class="{ 'rotate-180': active, 'rotate-0': !active }" />
    </div>

    <div
      class="text-clip px-5 text-xl/[1.5em] font-light text-gray-700 transition-all duration-200 ease-in"
      :class="{ 'max-h-[9999px] pb-5': active, 'hidden max-h-0': !active }">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  active: {
    type: Boolean,
    default: false,
  },
})
</script>
